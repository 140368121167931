<template>
    <v-app>
        <div>
            <Common-Home-Header :callpermissionflag="callpermissionflag" :authDialogue="auth_error_enable" :errorDialogue="error_enable"></Common-Home-Header>
            <div class="headers-tab">
                <v-divider></v-divider>
                <Header-Tabs-Component :tab_id="1" :is_user_logged_in="true"></Header-Tabs-Component>
            </div>

            <div v-if="isDesktopMode" class="forWeb">
                <div class="container">
                    <div class="fullContainer">
                        <div class="webHeading">
                            <div class="d-flex justify-center align-center">
                                <img src="https://s3iconimages.mymedicine.com.mm/back_button.svg" alt="back-button" @click="redirectToPreviousPage()">
                                <h1 class="orderDetails">{{ $t('Customer.MyOrders.Order_Details') }}</h1>
                            </div>
                            <!-- <v-btn elevation="0" class="heading12" @click="downloadInvoice()">
                                <img class="pa-0" src="https://d3vsnl8idgwrlw.cloudfront.net/orderDetailsDownloadNoBG.svg" alt="back-button" @click="downloadInvoice()">
                                <p class="downloadInvoice ma-0 light-blue-color-text">{{ $t('Customer.MyOrders.Download_Invoice') }}</p>
                            </v-btn> -->
                        </div>

                        <v-progress-circular
                            class="loadingIndicatorWeb"
                            indeterminate
                            color="grey"
                            v-if="!dataLoading"
                        ></v-progress-circular>
                    </div>
                    
                    <Order-Details-Component-Web :order="particularOrderFromDDB" v-if="dataLoading">

                    </Order-Details-Component-Web>
                </div>
            </div>

            <div v-else class="forMobile">
                <v-divider></v-divider>

                <div class="heading">
                    <div class="d-flex">
                        <img src="https://s3iconimages.mymedicine.com.mm/back_button.svg" alt="back-button" @click="redirectToPreviousPage()">
                        <p class="ma-0 px-3 title-heading font-weight-semi-bold">{{ $t('Customer.MyOrders.Order_Details') }}</p>
                    </div>
                    <!-- <div class="d-flex">
                        <img src="https://d3vsnl8idgwrlw.cloudfront.net/orderDetailsDownload.svg" alt="back-button" @click="downloadInvoice()">
                    </div> -->
                </div>
                
                <v-divider></v-divider>

                <v-progress-circular
                    class="loadingIndicator"
                    indeterminate
                    color="grey"
                    v-if="!dataLoading"
                ></v-progress-circular>

                <Order-Details-Component-Mobile :order="particularOrderFromDDB" v-if="dataLoading">

                </Order-Details-Component-Mobile>
            </div>
        
            <div v-if="isDesktopMode" class="pa-4">
                <Common-Home-Footer></Common-Home-Footer>
            </div>
        
        </div>
    </v-app>
    
</template>

<script>
import axios from 'axios';
import { axios_auth_instance_epharmacy_customer } from '../../../utils/axios_utils';

export default {
    name: 'Order-Details',
    data() {
        return {
            isDesktopMode: true,
            particularOrderFromDDB: [],
            dataLoading: false,
            callpermissionflag: false,
            auth_error_enable: false,
            error_enable: true
        }
    },
    components: {
        'Common-Home-Header': () => import('../../../components/navigationComponents/commonHomeHeader.vue'),
        'Common-Home-Footer': () => import('../../../components/navigationComponents/commonHomeFooter.vue'),
        'Order-Details-Component-Web': () => import('../../../components/ePharmacyComponents/orderDetailsComponentWeb.vue'),
        'Order-Details-Component-Mobile': () => import('../../../components/ePharmacyComponents/orderDetailsComponentMobile.vue'),
        'Header-Tabs-Component': () => import('../../../components/navigationComponents/headerTabsComponent.vue')
    },
    mounted() {
        if (window.xm){
      		document.title = '';
    	} else {
			document.title = 'Order Details';
		}
        if (this.$store.state.locale=='') {
            this.$i18n.locale = 'mm';
            this.$store.dispatch("changeLocale", this.$i18n.locale);
        }
        else
            this.$i18n.locale = this.$store.state.locale;

        this.checkScreenMode();
        window.addEventListener("resize",  this.checkScreenMode);

        // If user is logged out, pushing user to TelemedLogin
        this.currentCustomer = this.$cookies.get("customerToken");
        if(!this.currentCustomer) {
            if (!window.xm) {
                this.$router.push({
                    name: 'CustomerHomeScreen'
                });
            } else {
                this.$router.push({
                    name: 'CommonHomePage'
                });
            }
        } else {
            var params = this.$route.params;
            const orderID = params["orderID"];

            axios_auth_instance_epharmacy_customer.get("/orders/info/" + orderID).then((getParticularOrderResponse) => {
                this.dataLoading = true;
                this.particularOrderFromDDB = getParticularOrderResponse.data.data.orderDetails;
                this.callpermissionflag = true;
                this.error_enable = true;
            }).catch((getParticularOrderError) => {
                if (getParticularOrderError.status === 400) {
                    console.log('Error in getting AllMyOrders');
                    handleError(getParticularOrderError, this.$router, 'Error in getting AllMyOrders', '', '');
                    this.error_enable = true;
                } else if (getParticularOrderError.status === 401) {
                    handleError(getParticularOrderError, this.$router, 'getParticularOrderError', '', 'customerToken');
                    this.$cookies.remove('customerToken');
                    this.auth_error_enable = true;
                } else {
                    console.log('getParticularOrderError: ', getParticularOrderError);
                    this.error_enable = true;
                }
            })
        }
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.checkScreenMode);
    },
    methods: {
        userPageViewTable() {
            if (this.currentCustomer === null) {
                var userPageViewTableBody = {
                    pageName : 'orderDetailsPage',
                    typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                    pageName: 'orderDetailsPage',
                    typeOfUser: 'CUSTOMER',
                    token: this.currentCustomer
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
        },
        downloadInvoice() {
            console.log('Download Invoice clicked!');
        },
        checkScreenMode() {
            this.isDesktopMode = window.matchMedia("(min-width: 601px)").matches;
        },
        redirectToPreviousPage() {
            this.$router.go(-1);
        }
    }
}
</script>

<style lang="scss" scoped>
 @import '../../../scss/global.scss';
 @import '../../../scss/classes.scss';
 .headers-tab {
    @media (max-width: 600px) {
        display: none;
	}
 }

 .heading12 {
    background-color: #BDBDBD;
    // width: 180px;
    display: flex;
    justify-content: space-evenly !important;
    align-items: center !important;
    text-transform: none;
    padding: 1% !important;
    background-color: #EAF4FF !important;
}
 .orderDetails {
    width: 180px;
    align-items: center !important;
 }
 .downloadInvoice {
    padding-left: 5px;
}
.webHeading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 2.5%;
}
.fullContainer {
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.loadingIndicatorWeb {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
    margin-left: 49%;
    margin-right: 49%;
}
.loadingIndicator {
    margin-top: 50px !important;
}
.heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5px;
    padding-bottom: 5px;
}
.forWeb {
    background-color: #F4F6FA;
    padding-right: 130px;
    padding-left: 130px;
    padding-top: 40px;
    padding-bottom: 40px;
 }
.flexRow{
    display: flex;
    flex-direction: row;
}
.flexColum{
    display: flex;
    flex-direction: column;
}
.orderHeaderContainer{
    justify-content: space-between;
    margin-top: 12px;
}
.orderStatus{
    justify-content: space-around;
    background:rgba(255, 178, 109, 0.15);
    padding: unset !important

}
.orderStatusInExpansionPanel{
    display: flex;
    justify-content: flex-end !important;
    color: #FFB26D;
    font-weight: 600;
}
.expansionPanel .orderStatusInExpansionPanel{
    padding: 0px !important;
}
.orderStatusText{
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-left: 16px;
}
.timeLineText{
    text-align: left !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}
.timelineDate{
    text-align: left !important;
    color: #BDBDBD;
    font-weight: 500;
}
.activetimeLineText{
    text-align: left !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color:  #FFB26D;
}
.fileAttachementsCard {
  margin-top: 8px;
  border-radius: 10px;
}
.attachementsTitle {
  font-size: 18px;
  color: #333333;
  font-style: normal;
  font-weight: 600;
  padding-top: 10px;
  padding-left: 10px;
  text-align: left;
}
.individualFile {
  width: 100px;
}
.imgattachementClass {
  width: 40px;
}
.thumbnailattachementClass {
  width: 43px;
  height: 53px;
}
.attachementClass {
  width: 64px;
  height: 74px;
  border: #e0e0e0 solid 1px;
  padding: 10px;
  padding-bottom: 10px;
  margin: 15px;
  margin-bottom: 0px !important ;
  border-radius: 8px;
}
.fileNameClass {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 8px;
  font-size: 12px;
  color: #222b45;
}
.attachementWrapCalss {
  display: flex;
  flex-wrap: wrap;
}
.deliveryAddressTitle{
    font-weight: 600;
    font-size: 18px;
    line-height: 17px;
}
.addressType{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    color: #333333;
}
.deliverToName{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: #333333;
}
.addressText{
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: left;
    color: #333333;
}
</style>
<style>
.v-timeline::before {
    top: 55px;
    height: calc(100% - 110px)
}
</style>